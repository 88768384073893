import { ProviderService } from 'shared/models/providerService.model';
import { COMPARE_PRODUCTS_COMPRASION_TYPES } from 'constants/services/compare-products/compareProductsComprasionTypes.const';
import { ECompareProductsComprasionType } from 'enums/services/compare-products/compareProductsComparsionType.enum';
import { IRadioButton } from 'shared/models/radioGroup.model';
import { COMPARE_PRODUCTS_COMPRASION_TYPE_NAMES } from 'constants/services/compare-products/compareProductsComprasionTypeNames.const';
import Loader from 'shared/utils/loaderHelper.util';
import { CompareProductsApiService } from '../api/compareProductsApi.service';
import { IModelSelectionsModel, IModelSelectionsResponse } from 'models/services/api/modelSelection.model';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import Notificator from 'shared/services/notificator.service';
import { ICompareProductsCategoryToDisplay } from 'models/services/compare-products/compareProductsCategoryToDisplay.model';
import {
  ICompareProductsCharacteristicGroup,
  ICompareProductsCharacteristicItem,
  ICompareProductsCharacteristicValue,
} from 'models/services/compare-products/compareProductsCharacteristicGroup.model';
import { MpCompareProductsService } from './mpCompareProducts.service';
import ModalManager from 'shared/services/modalManager.service';
import Modal from 'shared/components/modals/Modal.vue';
import NotificatorSettings from 'shared/constants/notificator.const';
import SuccessIcon from 'shared/components/icons/SuccessIcon.vue';
import { AbortControllerUtil } from 'shared/utils/abortController.util';
import WindowWidthManager from 'shared/services/windowWidth.service';

export class MpCompareProductsModalService extends ProviderService {
  protected static readonly serviceName = 'mpCompareProductsModalService';

  public readonly scrollTransitionDuration = '.2s';

  private readonly windowWidth = WindowWidthManager.getAdaptivaRefs();

  public visible = ref(false);
  public comparationData = ref<IModelSelectionsResponse | undefined>();
  public selectedCategoryId = ref<number | undefined>();
  public selectedComprasionType = ref(ECompareProductsComprasionType.All);

  public currentProductCardIndex = ref(0);
  public stepSwiper = ref(0);
  public selectedCharacteristicItemIds = ref<Array<number>>([]);
  public mobileScrollLeft = ref(0);

  public loadingComparationData = Loader.getReactiveInstance();
  public loadingCharacteristics = Loader.getReactiveInstance();
  public loadingDeleteModel = Loader.getReactiveInstance();
  public loadingDeleteCategory = Loader.getReactiveInstance();
  public loadingReturnProduct = Loader.getReactiveInstance();

  // Кол-во товаров отображаемых в списке прокрутки
  public numberOfProductsForDisplay = computed(() => {
    if (this.windowWidth.isDesktop.value) {
      return 3;
    }

    return 5;
  });

  public comprasionTypesRadioButtons = computed<Array<IRadioButton>>(
    () => COMPARE_PRODUCTS_COMPRASION_TYPES.map((comprasionType) => ({
      value: comprasionType,
      label: COMPARE_PRODUCTS_COMPRASION_TYPE_NAMES.get(comprasionType),
      disabled: this.isComparationButtonDisabled(comprasionType),
    })),
  );

  public categoriesToDisplay = computed<Array<ICompareProductsCategoryToDisplay>>(
    () => this.comparationData.value?.categories.map(
      (category) => ({
        id: category.categoryId,
        name: category.categoryName,
        slug: category.slug,
        active: category.categoryId === this.selectedCategoryId.value,
        count: category.count,
      }),
    ) || [],
  );

  public selectedCategory = computed(
    () => this.comparationData.value?.categories.find(
      (category) => category.categoryId === this.selectedCategoryId.value,
    ),
  );

  public selectedCategoryModels = computed(
    () => this.selectedCategory.value?.models || [],
  );

  public characteristicsToDisplay = computed<Array<ICompareProductsCharacteristicGroup>>(
    () => this.selectedComprasionType.value === ECompareProductsComprasionType.OnlySelected
      ? [this.getSelectedCharacteristicGroup()]
      : [
        this.mapCharacteristics,
        this.filterCharacteristics,
      ].reduce(
        (characteristics, fn) => fn.call(this, characteristics),
        this.selectedCategory.value?.characteristics || [],
      ),
  );

  public emptyComparation = computed(
    () => !this.comparationData.value?.categories.length,
  );

  public emptyBannderDisplayed = computed(
    () => this.emptyComparation.value && !this.loadingComparationData.value,
  );

  public characteristicsDisplayed = computed(
    () => !!this.selectedCategory.value?.characteristics?.length
    && this.isExistsInCharacteristicsOneValue(),
  );

  public disabledCategories = computed(
    () => this.loadingDeleteCategory.value
      || this.loadingDeleteModel.value
      || this.loadingReturnProduct.value,
  );

  public translateXStyle = computed(
    () => `translateX(-${this.currentProductCardIndex.value * this.stepSwiper.value}px)`,
  );

  public mobileValuesTranslateXStyle = computed(
    () => `translateX(-${this.mobileScrollLeft.value}px)`,
  );

  public disabledNextButton = computed(
    () => this.currentProductCardIndex.value + this.numberOfProductsForDisplay.value
      >= this.selectedCategoryModels.value.length,
  );

  public disabledPrevButton = computed(
    () => this.currentProductCardIndex.value <= 0,
  );

  public disabledToggleSwitch = computed(
    () => !this.characteristicsDisplayed.value
      || this.selectedCategoryModels.value?.length === 1,
  );

  public loaderCharacteristicIsDisplay = computed(
    () => this.loadingCharacteristics.value
      || this.loadingReturnProduct.value,
  );

  private watchWindowWidth = watch(
    () => [
      ...(process.client
        ? [this.windowWidth.isDesktop.value, this.windowWidth.isLargeDesktop.value]
        : []),
    ],
    ([newIsDesktop, newIsLargeDesctop]) => {
      if (!newIsDesktop && !newIsLargeDesctop) {
        this.selectedCharacteristicItemIds.value = [];
        this.selectedComprasionType.value = ECompareProductsComprasionType.All;
      }
    },
  );

  private sentryServiceName = 'mp-compare-products-modal-service';
  private abortController?: AbortController;

  constructor(
    private readonly mpCompareProductsService: MpCompareProductsService,
    private readonly modalManager = process.client ? inject<ModalManager>(ModalManager.getServiceName()) : undefined,
    private readonly notificator = process.client ? inject<Notificator>(Notificator.getServiceName()) : undefined,
  ) {
    super();
  }

  public async toggleModal(): Promise<void> {
    this.visible.value
      ? this.closeModal()
      : await this.openModal();
  }

  public async openModal(): Promise<void> {
    this.visible.value = true;
    await this.initData();
  }

  public closeModal(): void {
    this.visible.value = false;
    this.selectedComprasionType.value = ECompareProductsComprasionType.All;
    this.selectedCharacteristicItemIds.value = [];
    this.selectedCategoryId.value = undefined;
    this.comparationData.value = undefined;
    this.currentProductCardIndex.value = 0;
  }

  public scrollProductsTo(numberOfProducts: number): void {
    this.currentProductCardIndex.value += numberOfProducts;
  }

  public async onSelectCategoryId(categoryId: number): Promise<void> {
    if (this.selectedCategoryId.value === categoryId) {
      return;
    }

    this.selectedCategoryId.value = categoryId;
    this.selectedComprasionType.value = ECompareProductsComprasionType.All;
    this.selectedCharacteristicItemIds.value = [];
    this.currentProductCardIndex.value = 0;
    this.mobileScrollLeft.value = 0;

    await nextTick();

    if (this.selectedCategory.value?.characteristics) {
      return;
    }

    await this.loadCharacteristics();
  }

  public async onDeleteProduct(product: IModelSelectionsModel): Promise<void> {
    if (this.loadingDeleteModel.value) {
      return;
    }

    this.loadingDeleteModel.activate();
    try {
      await CompareProductsApiService.deleteProductById(product.id);
      await this.deleteModelFromData(product);
      this.mpCompareProductsService.notifyModelsDeleted([product]);

      this.notificator?.clearAllNotifications();
      this.notificator?.showActionNotification(
        `Товар ${this.mpCompareProductsService.trimProductName(product?.modelName ?? '')} удален из сравнения`,
        'Вернуть',
        {
          ...NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS,
          icon: SuccessIcon,
        },
        async () => {
          await this.returnProductToCompare(product);
          this.notificator?.clearAllNotifications();
        },
      );

      this.recalculateCurrentProductCardIndex();
      this.updateComprasionType();
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        this.sentryServiceName,
        undefined,
        {
          extra: {
            comparationData: this.comparationData.value,
            modelDelete: product,
          },
        },
      );

      Notificator.showDetachedNotification('Ошибка при удалении товара');
    } finally {
      this.loadingDeleteModel.deactivate();
    }
  }

  public onCheckCharacteristicItemId(itemId: number, value: boolean): void {
    if (!value) {
      this.selectedCharacteristicItemIds.value = this.selectedCharacteristicItemIds.value
        .filter((id) => id !== itemId);
    } else {
      this.selectedCharacteristicItemIds.value.push(itemId);
    }

    if (!this.selectedCharacteristicItemIds.value.length) {
      this.selectedComprasionType.value = ECompareProductsComprasionType.All;
    }
  }

  public async onDeleteCurrentCategory(): Promise<void> {
    if (this.loadingDeleteCategory.value || !this.selectedCategoryId.value) {
      return;
    }

    const modalName = 'confirmDeleteCategoryModal';

    try {
      await this.modalManager?.openAsyncConfirmModal({
        bind: {
          name: modalName,
          zIndex: '1000 !important',
          contentText: `Все товары категории <span class="mm-font-500">${this.selectedCategory.value?.categoryName ?? ''}</span> будут удалены из сравнения безвозвратно. Подтвердите, что хотите удалить список полностью.`,
          titleText: 'Вы уверены, что хотите<br>удалить список сравнения?',
          cancelButtonText: 'Отменить',
          okButtonText: 'Удалить список',
          classes: 'modal fixed-bottom-modal',
        },
        component: Modal,
      });
    } catch {
      return;
    }

    this.loadingDeleteCategory.activate();
    try {
      await CompareProductsApiService.deleteAllByCategoryId(this.selectedCategoryId.value);
      this.mpCompareProductsService.notifyModelsDeleted(this.selectedCategory.value?.models || []);
      await this.deleteCategoryFromData(this.selectedCategoryId.value);
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        this.sentryServiceName,
        undefined,
        {
          extra: {
            categoryId: this.selectedCategoryId.value,
          },
        },
      );

      Notificator.showDetachedNotification('Ошибка при удалении списка');
    } finally {
      this.loadingDeleteCategory.deactivate();
    }
  }

  public clear(): void {
    this.watchWindowWidth();
  }

  private async deleteModelFromData(deletedModel: IModelSelectionsModel): Promise<void> {
    if (!this.comparationData.value || !this.selectedCategoryId.value) {
      return;
    }

    const selectedCategoryIndex = this.findIndexOfCategoryById(this.selectedCategoryId.value);
    if (selectedCategoryIndex === undefined) {
      return;
    }

    const selectedCategory = this.comparationData.value.categories[selectedCategoryIndex];
    selectedCategory.models = selectedCategory.models.filter((model) => model.id !== deletedModel.id);
    selectedCategory.count--;
    selectedCategory.characteristics = selectedCategory.characteristics?.map(
      (characteristic) => ({
        ...characteristic,
        items: characteristic.items.map((item) => ({
          ...item,
          values: item.values.filter((value) => value.productId !== deletedModel.modelId),
        })),
      }),
    );

    if (selectedCategory.models.length) {
      return;
    }

    await this.deleteCategoryFromData(selectedCategory.categoryId);
  }

  private async deleteCategoryFromData(deletedCategoryId: number): Promise<void> {
    if (!this.comparationData.value) {
      return;
    }

    this.comparationData.value.categories = this.comparationData.value.categories
      .filter((category) => category.categoryId !== deletedCategoryId);

    if (this.comparationData.value.categories.length) {
      await this.onSelectCategoryId(this.comparationData.value.categories[0].categoryId);
      return;
    }

    this.comparationData.value = undefined;
  }

  private async initData(): Promise<void> {
    await this.loadComparationData();
    if (!this.comparationData.value?.categories?.length) {
      return;
    }

    if (!this.selectedCategoryId.value) {
      this.selectedCategoryId.value = this.comparationData.value.categories[0].categoryId;
    }
    await this.loadCharacteristics();
  }

  private async loadCharacteristics(): Promise<void> {
    if (!this.selectedCategory.value) {
      return;
    }

    this.loadingCharacteristics.activate();
    this.abortController?.abort();
    try {
      this.abortController = AbortControllerUtil.createAbortController();
      const mappedCategory = await CompareProductsApiService.mapCategoryCharacteristics(
        this.selectedCategory.value,
      );

      const indexOfCategory = this.findIndexOfCategoryById(mappedCategory.categoryId);
      if (indexOfCategory !== undefined && this.comparationData.value) {
        this.comparationData.value.categories[indexOfCategory] = mappedCategory;
      }
      this.loadingCharacteristics.deactivate();
    } catch (error) {
      if (!AbortControllerUtil.isRequestAborted(error as Error)) {
        this.loadingCharacteristics.deactivate();

        clientSentry.captureServiceException(
          error,
          this.sentryServiceName,
          undefined,
          {
            extra: {
              comparationData: this.comparationData.value,
            },
          },
        );

        Notificator.showDetachedNotification('Ошибка при загрузке характеристик');
      }
    }
  }

  private findIndexOfCategoryById(categoryId: number): number | undefined {
    return this.comparationData.value?.categories.findIndex(
      (category) => category.categoryId === categoryId,
    );
  }

  private findIndexSelecetedCategoryModel(modelId: number): number | undefined {
    return this.selectedCategoryModels.value.findIndex(
      (model) => model.modelId === modelId,
    );
  }

  private async loadComparationData(): Promise<void> {
    this.loadingComparationData.activate();

    try {
      await this.setComparationData();
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        this.sentryServiceName,
      );

      Notificator.showDetachedNotification('Ошибка при загрузке данных сравнения');
    } finally {
      this.loadingComparationData.deactivate();
    }
  }

  private prepareValuesForDisplay(
    values: Array<ICompareProductsCharacteristicValue>,
  ): Array<ICompareProductsCharacteristicValue> {
    return values
      .filter(
        (value) => this.selectedCategoryModels.value.some((model) => model.modelId === value.productId),
      )
      .sort((leftValue, rightValue) => {
        const leftValueModelIndex = this.findIndexSelecetedCategoryModel(leftValue.productId) ?? 0;
        const rightValueModelIndex = this.findIndexSelecetedCategoryModel(rightValue.productId) ?? 0;

        return leftValueModelIndex < rightValueModelIndex
          ? -1
          : 1;
      });
  }

  private isCharacteristicItemSelected(itemId: number): boolean {
    return this.selectedCharacteristicItemIds.value.some((id) => id === itemId);
  }

  private isComparationButtonDisabled(comparationType: ECompareProductsComprasionType): boolean {
    if (!this.characteristicsDisplayed.value) {
      return true;
    }

    if (comparationType === ECompareProductsComprasionType.All) {
      return false;
    }

    if (comparationType === ECompareProductsComprasionType.OnlyDifferences) {
      return this.selectedCategory.value?.models.length === 1;
    }

    if (comparationType === ECompareProductsComprasionType.OnlySelected) {
      return !this.selectedCharacteristicItemIds.value.length;
    }

    return false;
  }

  private getSelectedCharacteristicGroup(): ICompareProductsCharacteristicGroup {
    return {
      name: 'Выбранные характеристики',
      items: (this.selectedCategory.value?.characteristics || [])
        .flatMap((characteristic) => this.mapCharacteristicItems(characteristic.items)
          .filter((characteristicItem) => characteristicItem.checked),
        ),
    };
  }

  private filterCharacteristics(
    characteristics: Array<ICompareProductsCharacteristicGroup>,
  ): Array<ICompareProductsCharacteristicGroup> {
    return characteristics
      .filter((characteristic) => !!characteristic.items.length);
  }

  private mapCharacteristics(
    characteristics: Array<ICompareProductsCharacteristicGroup>,
  ): Array<ICompareProductsCharacteristicGroup> {
    return characteristics.map(
      (characteristic) => ({
        ...characteristic,
        items: [
          this.mapCharacteristicItems,
          this.filterCharacteristicItems,
        ].reduce(
          (characteristicItems, fn) => fn.call(this, characteristicItems),
          characteristic.items,
        ),
      }),
    );
  }

  private isCharacteristicItemHasDifferenceValues(
    characteristicItem: ICompareProductsCharacteristicItem,
  ): boolean {
    return !characteristicItem.values.every(
      (value, _, values) => String(value.value).trim() === String(values[0].value).trim(),
    );
  }

  private filterCharacteristicItemByComparasionType(
    characteristicItem: ICompareProductsCharacteristicItem,
  ): boolean {
    if (this.selectedComprasionType.value === ECompareProductsComprasionType.OnlyDifferences) {
      return this.isCharacteristicItemHasDifferenceValues(characteristicItem);
    }

    return true;
  }

  private isCharacteristicItemHasDefinedValue(
    characteristicItem: ICompareProductsCharacteristicItem,
  ): boolean {
    return characteristicItem.values.some((value) => value.value);
  }

  private filterCharacteristicItems(
    characteristicItems: Array<ICompareProductsCharacteristicItem>,
  ): Array<ICompareProductsCharacteristicItem> {
    return characteristicItems
      .filter((characteristicItem) => this.filterCharacteristicItemByComparasionType(characteristicItem))
      .filter((characteristicItem) => this.isCharacteristicItemHasDefinedValue(characteristicItem));
  }

  private mapCharacteristicItems(
    characteristicItems: Array<ICompareProductsCharacteristicItem>,
  ): Array<ICompareProductsCharacteristicItem> {
    return characteristicItems.map((characteristicItem) => ({
      ...characteristicItem,
      checked: this.isCharacteristicItemSelected(characteristicItem.id),
      values: this.prepareValuesForDisplay(characteristicItem.values),
    }));
  }

  private recalculateCurrentProductCardIndex(): void {
    const currentProductCardIndex = this.currentProductCardIndex.value;
    const modelsLength = this.selectedCategoryModels.value.length;

    if ((currentProductCardIndex + this.numberOfProductsForDisplay.value) > modelsLength) {
      const newIndex = modelsLength - this.numberOfProductsForDisplay.value;
      this.currentProductCardIndex.value = newIndex < 0 ? 0 : newIndex;
    }
  }

  private isExistsInCharacteristicsOneValue(): boolean {
    return !!this.selectedCategory.value?.characteristics?.some(
      (characteristic) => characteristic.items.some(
        (item) => item.values.some(
          (value) => value.value,
        ),
      ),
    );
  }

  private updateComprasionType(): void {
    if ((this.selectedCategory.value?.models.length || 0) > 1
      || this.selectedComprasionType.value !== ECompareProductsComprasionType.OnlyDifferences) {
      return;
    }

    this.selectedComprasionType.value = ECompareProductsComprasionType.All;
  }

  private async setComparationData(): Promise<void> {
    this.comparationData.value = await CompareProductsApiService.getAll();
  }

  private async returnProductToCompare(product: IModelSelectionsModel): Promise<void> {
    this.loadingReturnProduct.activate();

    try {
      await this.mpCompareProductsService.addToCompare(product);
      await this.setComparationData();
      await this.loadCharacteristics();
    } finally {
      this.loadingReturnProduct.deactivate();
    }
  }
}
