import { BaseCompareProductsService } from './baseCompareProducts.service';
import Notificator from 'shared/services/notificator.service';
import NotificatorSettings from 'shared/constants/notificator.const';
import SuccessIcon from 'shared/components/icons/SuccessIcon.vue';
import { ICompareProductsCreateProductData } from 'models/services/compare-products/compareProductsCreateProductData.model';
import { MpCompareProductsModalService } from './mpCompareProductsModal.service';
import { CompareProductsApiService } from 'services/api/compareProductsApi.service';
import { IModelSelectionCreateData, IModelSelectionsModel } from 'models/services/api/modelSelection.model';
import { EModelSelectionsSelectionType } from 'enums/services/api/modelSelectionsSelectionType.enum';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { ObserverManager } from 'shared/services/observer-manager/observerManager.service';
import { Observer } from 'shared/services/observer-manager/observer.service';
import Loader from 'shared/utils/loaderHelper.util';
import { debounce } from 'lodash-es';

export class MpCompareProductsService extends BaseCompareProductsService {
  public count = ref(0);

  public loadingAddToCompare = Loader.getReactiveInstance();

  private deleteProductsObserverManager = new ObserverManager<Array<number>>();
  private sentryServiceName = 'mp-compare-products-service';
  private readonly mpCompareProductsModalService: MpCompareProductsModalService;

  private debounceLoadCount = debounce(this.loadCount.bind(this), 300);

  constructor(
    private readonly notificator = process.client ? inject<Notificator>(Notificator.getServiceName()) : undefined,
  ) {
    super();

    this.mpCompareProductsModalService = new MpCompareProductsModalService(this),
    provide(MpCompareProductsModalService.getServiceName(), this.mpCompareProductsModalService);
  }

  public async addToCompare(
    product: IModelSelectionCreateData | IModelSelectionsModel,
  ): Promise<IModelSelectionsModel | undefined> {
    if (this.loadingAddToCompare.value) {
      return;
    }

    this.closeAllNotifications();

    this.loadingAddToCompare.activate();
    try {
      const addedProduct = await CompareProductsApiService.addProductToCompare(product);

      if (!product.id) {
        this.notificator?.showActionNotification(
          `Товар ${this.trimProductName(product?.modelName ?? '')} добавлен к сравнению`,
          'Отменить',
          {
            ...NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS,
            icon: SuccessIcon,
          },
          async () => {
            await this.removeFromCompare(addedProduct);
            this.closeAllNotifications();
          },
        );
      }

      this.debounceLoadCount();
      return addedProduct;
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        this.sentryServiceName,
        undefined,
        {
          extra: {
            product,
          },
        },
      );

      Notificator.showDetachedNotification('Ошибка при добавлении товара к сравнению');
    } finally {
      this.loadingAddToCompare.deactivate();
    }
  }

  public static createProductToCompare<T extends ICompareProductsCreateProductData>(product: T): IModelSelectionCreateData {
    return {
      selectionType: EModelSelectionsSelectionType.Comprasion,
      categoryId: Number(product.categoryId),
      modelId: Number(product.id),
      modelName: product.name,
      categoryName: product.categoryName,
    };
  }

  public async deleteFromCompare<T>(item: T): Promise<void> {
    console.warn(item);
  }

  public toggleModal(): void {
    this.closeAllNotifications();
    this.mpCompareProductsModalService.toggleModal();
  }

  public async loadCount(): Promise<void> {
    try {
      this.count.value = await CompareProductsApiService.getCount();
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        this.sentryServiceName,
        undefined,
        {
          tags: {
            function: 'loadCount',
          },
        },
      );

      Notificator.showDetachedNotification('Ошибка при получении количества выбранных к сравнению товаров');
    }
  }

  public async removeFromCompare(model: IModelSelectionsModel): Promise<void> {
    try {
      await CompareProductsApiService.deleteProductById(model.id);
      this.notifyModelsDeleted([model]);
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        this.sentryServiceName,
        undefined,
        {
          extra: {
            model,
          },
        },
      );

      Notificator.showDetachedNotification('Ошибка при удалении товара из сравнения');
    }
  }

  public subscribeOnDeleteProducts(handler: (modelIds?: Array<number>) => void): Observer<Array<number>> {
    return this.deleteProductsObserverManager.subscribe(handler);
  }

  public unsubscribeOnDeleteProducts(observer: Observer<Array<number>>): void {
    this.deleteProductsObserverManager.unsubscribe(observer);
  }

  public notifyModelsDeleted(models: Array<IModelSelectionsModel>): void {
    this.debounceLoadCount();
    this.deleteProductsObserverManager.notify(models.map((model) => model.modelId));
  }

  public trimProductName(productName: string, numberOfChars = 50): string {
    return productName.length > numberOfChars
      ? `${productName.slice(0, numberOfChars)}...`
      : productName;
  }

  private closeAllNotifications(): void {
    this.notificator?.clearAllNotifications();
  }
}
