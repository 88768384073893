import { AuthHelper } from 'utils/authHelper.util';
import { EModelSelectionsSelectionType } from 'enums/services/api/modelSelectionsSelectionType.enum';
import {
  IModelSelectionsResponse,
  IModelSelectionsModel,
  IModelSelectionCreateData,
  IModelSelectionsCategory,
} from 'models/services/api/modelSelection.model';

export class CompareProductsApiService {
  public static getAll(): Promise<IModelSelectionsResponse> {
    return AuthHelper.fetch('/api/v1/model_selections', {
      method: 'GET',
      params: {
        selectionType: EModelSelectionsSelectionType.Comprasion,
      },
    });
  }

  public static addProductToCompare(body: IModelSelectionCreateData): Promise<IModelSelectionsModel> {
    return AuthHelper.fetch('/api/v1/model_selections', {
      method: 'POST',
      body,
    });
  }

  public static deleteAllByCategoryId(categoryId: number): Promise<void> {
    return AuthHelper.fetch('/api/v1/model_selections', {
      method: 'DELETE',
      params: {
        selectionType: EModelSelectionsSelectionType.Comprasion,
        categoryId,
      },
    });
  }

  public static getCount(): Promise<number> {
    return AuthHelper.fetch('/api/v1/model_selections/count/comparison', {
      method: 'GET',
    });
  }

  public static deleteProductById(modelId: number): Promise<void> {
    return AuthHelper.fetch(`/api/v1/model_selections/${modelId}`, {
      method: 'DELETE',
    });
  }

  public static mapCategoryCharacteristics(
    category: IModelSelectionsCategory,
  ): Promise<IModelSelectionsCategory> {
    return AuthHelper.fetch('/api/v1/compare/characteristics', {
      method: 'POST',
      body: category,
    });
  }
}
